<template>
    <!--====================  service tab area ====================-->
    <div class="service-tab-area section-space--inner--120">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="section-title-area text-center">
                        <h2 class="section-title section-space--bottom--50">申请流程 <span class="title-icon"></span></h2>
                    </div>
                </div>
                <div class="col-lg-12">
                    <!-- service tab wrapper -->
                    <div class="service-tab-wrapper">
                        <div class="row no-gutters">
                            <div class="col-md-4">
                                <div class="nav nav-tabs flex-column service-tab__link-wrapper">
                                    <a class="nav-item nav-link" @click.prevent="setActive('home')" :class="{ active: isActive('home') }"> <span class="icon"><i class="flaticon-002-welding"></i></span> <span class="text">01 - 评估</span></a>
                                    <a class="nav-item nav-link" @click.prevent="setActive('profile')" :class="{ active: isActive('profile') }"> <span class="icon"><i class="flaticon-004-walkie-talkie"></i></span> <span class="text">02 - 申请</span></a>
                                    <a class="nav-item nav-link" @click.prevent="setActive('about')" :class="{ active: isActive('about') }"> <span class="icon"><i class="flaticon-015-cart"></i></span> <span class="text">03 - 录取</span></a>
                                    <a class="nav-item nav-link" @click.prevent="setActive('contact')" :class="{ active: isActive('contact') }"> <span class="icon"><i class="flaticon-010-tank-1"></i></span> <span class="text">04 - 抵达</span></a>
                                </div>
                            </div>
                            <div class="col-md-8">
                                <div class="tab-content service-tab__content-wrapper">
                                    <div class="tab-pane fade" :class="{ 'active show': isActive('home') }" id="home">
                                        <div class="service-tab__single-content-wrapper bg-img" :style="{'background-image':'url(assets/img/flow1.jpg)'}">
                                            <div class="service-tab__single-content">
                                                <h3 class="service-tab__title">评估</h3>
                                                <p class="service-tab__text">-留学顾问对学生背景进行评估，根据学生的实际情况定制合适的留学方案，推荐合适的院校</p>
                                                <p class="service-tab__text">-确认留学方案后，签订留学服务协议书，缴纳服务费或押金，留学顾问指导学生准备相关学院的申请材料以及签证材料</p>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" :class="{ 'active show': isActive('profile') }" id="profile">
                                        <div class="service-tab__single-content-wrapper bg-img" :style="{'background-image':'url(assets/img/flow1.jpg)'}">
                                            <div class="service-tab__single-content">
                                                <h3 class="service-tab__title">申请</h3>
                                                <p class="service-tab__text">-留学顾问及文案老师检查学生的申请材料，向学院递交课程申请，及时处理学院申请过程中的问题并通知学生申请进度。</p>
                                                <p class="service-tab__text">-学生在获得学院录取通知书后，留学顾问与学生沟通是否接受学院的录取通知书，并签翎院校学生合同及相关文件。</p>


                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" :class="{ 'active show': isActive('about') }" id="about">
                                        <div class="service-tab__single-content-wrapper bg-img" :style="{'background-image':'url(assets/img/flow1.jpg)'}">
                                            <div class="service-tab__single-content">
                                                <h3 class="service-tab__title">录取</h3>
                                                <p class="service-tab__text">-学生在取得学院录取后，开始办理学生签证申请，学院约 1 - 6 周左右通知学生是否取得新加坡移民局的学生证批准函。</p>
                                                <p class="service-tab__text">-在学院通知学生签证批准后，学生需要交纳学费，学院发放学生签证原则批准函给学生，购买机票和安排住宿，前往新加坡。</p>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" :class="{ 'active show': isActive('contact') }" id="contact">
                                        <div class="service-tab__single-content-wrapper bg-img" :style="{'background-image':'url(assets/img/flow1.jpg)'}">
                                            <div class="service-tab__single-content">
                                                <h3 class="service-tab__title">抵达</h3>
                                                <p class="service-tab__text">-抵达新加坡后，学生服务专员会安排学生到学院报到，预约体检，前往移民与关卡局领取学生证和开设银行账户等。</p>
                                                <p class="service-tab__text">-学院正式开学后，如学生遇到任何专业，升学和生活问题等，都可以随时联系学生服务专员</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--====================  End of service tab area  ====================-->
</template>

<script>
    export default {
        data(){
            return {
                activeItem: 'home'
            }
        },
        methods: {
            isActive (menuItem) {
                return this.activeItem === menuItem
            },
            setActive (menuItem) {
                this.activeItem = menuItem
            }
        }
    };
</script>