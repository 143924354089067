<template>
    <div class="hero-alider-area ">
        <div class="hero-slider__container-area">
            <div class="swiper-container hero-slider__container" >
                <div class="swiper-wrapper hero-slider__wrapper">
                    <swiper :options="swiperOption">
                        <!--=======  single slider item  =======-->
                        <div class="swiper-slide hero-slider__single-item"
                             :style="{ backgroundImage: `url(${item.image})` }"
                             v-for="item in swiperData"
                             :key="item.id">
                            <div class="slider-container" @click="toInfo(item.link)">
                                <div class="slider-text  myT">
                                    <h2 class="hero-slider__title">{{ item.title }}</h2>
                                    <p class="hero-slider__text">{{ item.content }}</p>
                                </div>
                            </div>
                        </div>
                        <!--=======  End of single slider item  =======-->
                        <!-- <div class="ht-swiper-button-prev ht-swiper-button-prev-13 ht-swiper-button-nav d-none d-xl-block"><i class="ion-ios-arrow-left"></i></div>
                        <div class="ht-swiper-button-next ht-swiper-button-next-13 ht-swiper-button-nav d-none d-xl-block"><i class="ion-ios-arrow-forward"></i></div> -->
                    </swiper>
                    <div class="slider-form-container">
                        <ConsultantForm />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ConsultantForm from "@/components/ConsultantForm";
    import {httpService} from "@/services/http";
    import {constants} from "@/services/constants";
    export default {
      components: {
        ConsultantForm,
      },
      data() {
            return {
              swiperData:'',
              swiperOption: {
                    speed: 1500,
                    loop: true,
                    effect: 'fade',
                    autoplay: { delay: 3000 },
                    spaceBetween : 30,
                    navigation: {
                        nextEl: '.ht-swiper-button-next',
                        prevEl: '.ht-swiper-button-prev'
                    },
                },
            };
        },
      created() {
        //获取头部轮播信息
        httpService.get(constants.HEADER_CAROUSELS1).then((data)=>{
          this.swiperData = data.body.slice(3,6)
          console.log(1,this.swiperData)
        })
      },
      methods:{
        toInfo(url){
          window.open(url)
        }
      }
    };
</script>

<style lang="scss">
//.hero-alider-area{
//  height: 723px;
//}
.myT:hover{
  cursor: pointer;
}

    .hero-slider__title, .hero-slider__text {
        color: #F8F8F8;
        margin-left: 12%;
        margin-bottom: 0;
        font-family: 'DM Sans';
        font-size: 50px;
        line-height: 78px;
        min-width: 800px;

        @media only screen and (max-width: 1200px) {
            font-size: 30px;
        }

        @media only screen and (max-width: 800px) {
            font-size: 30px;
        }
    }

    .slider-container {
        height: 723px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .slider-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: rgba(0, 125, 209, 0.7);
        border-top-right-radius: 181px;
        border-bottom-right-radius: 181px;
        height: 300px;
        width: 50%;
        min-width: 400px;

        @media only screen and (max-width: 1200px) {
            height: 280px;
        }
    }

    .slider-form-container {
        position: absolute;
        top: 93px;
        bottom: 0;
        right: 100px;
        z-index: 999;
    }
    @media screen and (max-width: 500px) {
      .slider-form-container {
        left: 0;
        top: 0;
      }
    }
</style>
