<template>
    <!--====================  footer area ====================-->
    <div class="footer-area dark-bg">
      <div class="row">
          <img width="98%" height="100%" src="../assets/myImage/Group.png">
      </div>
    </div>
    <!--====================  End of footer area  ====================-->
</template>

<script>
    import FooterAbout from '@/components/FooterAbout.vue';
    import FooterLink from '@/components/FooterLink.vue';
    import ContactWidget from '@/components/ContactWidget.vue';

    import data from '../data/footer.json'
    export default {
        components: {
            FooterAbout,
            FooterLink,
            ContactWidget
        },
        data () {
            return {
                data
            }
        }
    };
</script>

<style lang="scss">

</style>